<template>
  <footer id="vm-cola-footer" class="vm-cola-footer" :class="`${className}`">
    <!-- <div v-if="className.indexOf('Payment') < 0" class="content-footer" :class="`${position}`">
      <template v-if="position === 'home'">
        <router-link :to="{ name: 'vmColaProducts' }" title="Shop Now" class="btn-shop-now">Shop Now</router-link>
      </template>
      <div v-else-if="position === 'products'" class="shopping-bag">
        <div class="my-bag">
          <div class="icon">
            <img src="/static/img/vm-cola/icon-shopping-bag.svg" alt="" class="icon-bag" />
            <span :class="{ active: cartQty > 0 }">{{ cartQty }}</span>
          </div>
          <div class="text">My Shopping Bag</div>
        </div>
        <router-link v-if="cartQty > 0" to="/vmcola/order" title="Go To Shopping Bag" class="gotocart"
          >Go To Shopping Bag</router-link
        >
        <button v-else title="Go To Shopping Bag" class="gotocart" disabled>Go To Shopping Bag</button>
      </div>
      <template v-else>
        <router-link :to="url_from" class="back">BACK</router-link>
        <router-link
          v-if="position == 'order'"
          to="/vmcola/order/login"
          class="checkout"
          :class="{ disabled: cartProductNumber < 1 }"
          >CHECKOUT NOW</router-link
        >
      </template>
    </div> -->
    <!-- <BannerFooter v-if="position !== 'home'" :class="{ bottom: className.indexOf('Payment') > 0 }" /> -->

    <template v-if="footer_primary_banner || footer_secondary_banner">
      <template v-if="['home', 'payment'].includes(position) && footer_primary_banner">
        <img :src="footer_primary_banner" alt="img-home-v2" />
      </template>
      <template v-else-if="footer_secondary_banner">
        <img :src="footer_secondary_banner" alt="img-other-v2" />
      </template>
    </template>
    <template v-else>
      <img src="/static/img/vmcola-footer-with-phone.svg" />
    </template>
  </footer>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'Footer',
  components: {
    // BannerFooter: () => import('@/components/vm-cola/snippets/footer/banner'),
  },
  props: {
    position: {
      type: String,
      required: false,
      default: () => 'home',
    },
    className: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    footer_primary_banner() {
      return this.$store.state.vmCola.cola_primary_footer_banner?.[0]?.images?.[0]?.url;
    },
    footer_secondary_banner() {
      return this.$store.state.vmCola.cola_secondary_footer_banner?.[0]?.images?.[0]?.url;
    },
    cartProductNumber() {
      return this.$store.state.vmCola.footer_cart.length;
    },
    cartQty() {
      const cartQtyFromService = get(this.$store.state, 'vmCola.cart_qty', 0);
      const cart_fetched = this.$store.state.vmCola.cart_fetched;

      // if cart already fetched, on first hover use carts
      if (cart_fetched) {
        const carts = get(this.$store.state, 'vmCola.footer_cart', []);
        let result = 0;

        if (carts) {
          for (let i = 0; i < carts.length; i++) {
            result = result + carts[i].qty;
          }
        }

        return result;
      }

      return cartQtyFromService;
    },
    url_from() {
      if (this.position == 'order') {
        return '/vmcola/products';
      } else if (this.position == 'login') {
        return '/vmcola/order';
      } else {
        return '/vmcola';
      }
    },
  },
  watch: {
    cartQty(newVal) {
      if (newVal) {
        return newVal;
      }
    },
  },
  mounted() {
    // console.log(this.position.indexOf('payment'));
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/sass/mixin-revamp';

.vm-cola-footer {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  flex-wrap: wrap;
  bottom: 0;

  img {
    border-radius: 22px 22px 0 0;
    width: 100%;
  }
}

// .vm-cola-footer {
//   height: auto;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   position: fixed;
//   flex-wrap: wrap;
//   bottom: 0;
//   &.vmColaOrder,
//   &.vmColaLogin {
//     height: 25.73vh;
//     .content-footer {
//       background: #fff;
//     }
//   }
//   &.vmColaPayment,
//   &.vmColaPaymentSuccess,
//   &.vmColaPaymentFailed {
//     height: 14.15vh;
//     .content-footer {
//       background: #fff;
//     }
//   }
//   &.vmColaHome {
//     height: 17.55vh;
//     .content-footer {
//       background: transparent;
//     }
//   }
//   .btn-shop-now {
//     background: #000000;
//     @include border-radius(8px);
//     text-transform: uppercase;
//     display: flex;
//     justify-content: center;
//     margin: 0 auto;
//     @include box-shadow(3px 3px 11px 0 rgba(0, 0, 0, 0.29));
//     max-width: 463px;
//     padding: 24px 0;
//     height: 86px;
//     width: 100%;
//     letter-spacing: 6px;
//     font: 27px/37px $brandon-bold;
//     color: #ffffff;
//   }
//   .content-footer {
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     background: #f6e2e2;
//     &.order,
//     &.payment {
//       height: 224px;
//       background: #ffffff !important;
//       padding: 48px 48px 90px;
//     }
//     &.login {
//       height: 224px;
//       background: #fef5f6 !important;
//       padding: 48px 48px 90px;
//     }
//     &.products {
//       height: 134px;
//       width: 100%;
//       padding: 24px;
//       background: #f9c0d2 !important;

//       .shopping-bag {
//         width: 100%;
//         height: 100%;
//         display: flex;
//         &.active {
//         }
//       }

//       .my-bag {
//         display: block;
//         float: left;
//         padding-left: 16px;

//         .text {
//           margin: 12px 0 0;
//           font: 18px/25px $brandon-medium;
//           letter-spacing: 3px;
//           color: #000000;
//           text-transform: uppercase;
//         }
//       }

//       .my-bag span {
//         font: 48px/48px $brandon-bold;
//         letter-spacing: 1px;
//         color: #000000;
//       }

//       .gotocart {
//         height: 100%;
//         display: flex;
//         white-space: nowrap;
//         flex-wrap: nowrap;
//         justify-content: center;
//         align-items: center;
//         color: #ffffff;
//         letter-spacing: 6px;
//         padding-left: 35px;
//         padding-right: 35px;
//         background: #da2a52;
//         border: none;
//         @include border-radius(8px);
//         text-transform: uppercase;
//         font: 27px/37px $brandon-bold;
//         &[disabled],
//         &.disabled {
//           background: #e5e5e5;
//         }
//       }

//       .icon-bag {
//         width: 44px;
//         height: 48px;
//         margin-right: 24px;
//         float: left;
//       }
//     }
//     .my-bag {
//       display: block;
//       width: 100%;
//       .icon {
//         display: flex;
//         .icon-bag {
//           width: 35px;
//           height: 45px;
//           margin-right: 15px;
//         }
//         span {
//           font: 48px/48px $brandon-bold;
//           letter-spacing: 1px;
//           color: #000000;
//           &.number {
//             color: #da2a52;
//           }
//           &.active {
//             color: #da2a52;
//           }
//         }
//       }
//       .text {
//         margin: 0;
//         font: 16px/25px $brandon-medium;
//         letter-spacing: 2px;
//         color: #000000;
//         text-transform: uppercase;
//       }
//     }
//     a {
//       height: 100%;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       padding-left: 35px;
//       padding-right: 35px;
//       @include border-radius(8px);
//       text-transform: uppercase;
//       font: 27px/37px $brandon-bold;
//       letter-spacing: 6px;
//       &.gotocart {
//         color: #ffffff;
//         background: #e5e5e5;
//       }
//       &.back {
//         color: #ffffff;
//         background: #000;
//       }
//       &.checkout {
//         color: #ffffff;
//         background: #da2a52;
//         padding-left: 87px !important;
//         padding-right: 87px !important;
//         &.disabled {
//           background: #e5e5e5;
//           cursor: not-allowed;
//           pointer-events: none;
//         }
//       }
//     }
//   }

//   .banner-footer {
//     background: #e7e7e7;
//     max-height: 270px;
//     height: 100%;
//     width: 100%;
//     display: flex;

//     /deep/ {
//       .VueCarousel-slide {
//         width: 100%;
//         max-width: 100%;
//         height: 100%;
//       }
//       .VueCarousel,
//       .VueCarousel-wrapper,
//       .VueCarousel-inner {
//         width: 100%;
//       }

//       .VueCarousel-inner {
//         height: 100% !important;
//       }
//       .VueCarousel-pagination {
//         position: absolute;
//         bottom: 10px;
//       }
//       .VueCarousel-dot-container {
//         margin: 0 !important;
//       }
//       .VueCarousel-dot {
//         width: 18px !important;
//         padding: 0 !important;
//         height: 18px !important;
//         margin: 0 6px !important;
//         &--active {
//           width: 64px !important;
//           @include border-radius(9px !important);
//           @include border-box();
//           background-clip: border-box;
//         }
//         &:hover,
//         &:focus {
//           outline: none;
//           border: none;
//         }
//       }

//       .item-slide {
//         width: 100%;
//         height: 100%;
//         overflow: hidden;

//         img {
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//         }
//       }
//     }
//   }
//   .bottom {
//     position: absolute;
//     bottom: 0;
//   }
// }
</style>
